:root {
  --theme-black: black;
  --theme-grey: #f0f0f0;
  --theme-dark-grey: #3b3b3b;
  --theme-red: rgb(250, 67, 67);
}
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
* {
  margin: 0px;
  padding: 0px;
}
*:focus {
  outline: none;
}
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.font-pt-sans {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.font-raleway {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.footer {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.myborder {
  border: 5px solid var(--theme-red);
}
.display-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.display-flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.display-flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.display-flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.display-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.display-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.display-flex-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}
.display-flex-align-items-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.display-flex-align-items-top-column
{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
.btn-navbar {
  background-color: white;
  border: 2px solid white;
  width: 16%;
  padding: 10px 0px;
  font-weight: 700;
  font-size: 1em;
}
.btn-navbar-dropdown {
  background-color: white;
  border: 2px solid white;
  padding: 7px 15px;
  font-size: 1em;
  text-align: left;
}
.btn-navbar-dropdown:hover {
  color: var(--theme-red);
}
.width-100 {
  width: 100%;
}
.width-0 {
  width: 0%;
}
.position-fixed-top {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
}
.btn-navbar-hover-underline {
  font-family: cursive;
  position: relative;
  top: 7px;
  border: 4px solid var(--theme-red);
  border-bottom-left-radius: 100%;
  border-top-right-radius: 100%;
}
.height-10px {
  height: 8px;
}
.rotate-0 {
  transform: rotate(0deg);
}
.transition-half-sec {
  transition: all 0.5s;
}
.rotate-180 {
  transform: rotate(180deg);
}
.color-red {
  color: var(--theme-red);
}
.height-0 {
  transform: scaleY(0);
  transform-origin: top;
}
.height-100 {
  height: 100%;
}
.position-absolute-dropdown {
  position: relative;
  left: 60vw;
  top: 0px;
}
.border-left-bottom-red {
  border-left: 1.5px solid var(--theme-red);
  border-bottom: 1.5px solid var(--theme-red);
}
.height-100vh {
  height: 100vh;
}
.border-radius-left-bottom {
  border-bottom-right-radius: 40%;
}
.border-radius-left-top {
  border-top-left-radius: 40%;
}

/* slant-div */
.full-screen {
  height: 100vh;
  width: 100vw;
  z-index: -1;
  position: relative;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.div1 {
  position: absolute;
  top: 0px;
  left: 0px;
  min-height: 100%;
  width: 100%;
  z-index: 1;
  background-image: url("https://growthstudio.co.in/images/main-landscape-2.webp");
  background-size: cover;
  background-position-y: bottom;
}

@media (min-width: 700px) {
  .position-mainpage-content {
    position: absolute;
    top: 30vh;
  }
  .fs-large-css {
    font-size: 4em;
  }
}
@media (min-width: 400px) and (max-width: 699px) {
  .position-mainpage-content {
    position: absolute;
    top: 25vh;
  }
  .fs-large-css {
    font-size: 3em;
  }
}
@media (min-width: 250px) and (max-width: 399px) {
  .position-mainpage-content {
    position: absolute;
    top: 10vh;
  }
  .fs-large-css {
    font-size: 2.5em;
  }
}
.div2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 120%;
  right: -15vw;
  width: 50%;
  border-spacing: 0;
  z-index: 2;
  transform: skewX(15deg);
  overflow: hidden;
  border-left: 30px solid white;
  outline: none;
  border-collapse: collapse;
  background-image: url("https://growthstudio.co.in/images/mainpage-portrait-2.webp");
  background-size: cover;
  background-position-y: top;
  padding-top: 50px;
  padding-bottom: 0px;
}
.fs-small-css {
  font-size: 0.8em;
}
@media (min-width: 700px) and (max-width: 2000px) {
  .display-on-lappy {
    display: block;
  }
  .display-on-phone {
    display: none;
  }
}
@media (min-width: 1100px) {
  .display-on-min-width-1100px {
    display: block;
  }
}
@media (max-width: 1099px) {
  .display-on-min-width-1100px {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 699px) {
  .display-on-lappy {
    display: none;
  }
  .display-on-phone {
    display: block;
  }
}
.height-30 {
  height: 33%;
  width: 100%;
  margin-right: auto;
  padding-left: 100px;
  transform: skewX(-15deg);
  border-bottom: 25px solid white;
  position: relative;
  left: -50px;
  color: white;
  font-size: 1.5em;
  font-weight: 700;
}

.height-50vh-dot {
  min-height: 50vh;
}
.bg-white-css {
  background-color: white;
}
.height-50vh-exp {
  min-height: 70vh;
}
.color-dark-grey {
  color: var(--theme-dark-grey);
}
.color-lighter-grey {
  color: #efefef;
}
.bg-light-grey {
  background-color: var(--theme-grey);
}
.fs-small {
  font-size: 14px;
}
.letter-spacing-2px {
  letter-spacing: 1.2px;
  line-height: 25px;
}
.btn-universal {
  background-color: black;
  color: white;
  padding: 10px 20px;
  font-size: 1.1em;
  border: 1px solid transparent;
}
.btn-universal:hover {
  background-color: var(--theme-red);
  color: white;
  padding: 10px 20px;
  font-size: 1.1em;
  border: 1px solid transparent;
}

.btn-universal-converse:hover {
  background-color: black;
  color: white;
  padding: 10px 20px;
  font-size: 1em;
  border: 1px solid transparent;
  transition: all 0.5s;
}
.btn-universal-converse:disabled {
  opacity: 0.5;
}
.btn-universal-converse {
  background-color: var(--theme-red);
  color: white;
  padding: 10px 20px;
  font-size: 1em;
  border: 1px solid transparent;
  font-weight: 600;
}

.screen-100 {
  height: 100vh;
  background-image: url("https://ld-wt73.template-help.com/wt_63506/images/parallax-3.jpg");
  background-size: cover;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: -2;
}

.counter-card {
  background-color: rgba(255, 255, 255, 0.369);
}
.counter-card-count {
  color: white;
  font-size: 4.5em;
  font-weight: 500;
}
.height-70vh {
  height: 70vh;
}
.project-image {
  overflow: hidden;
  position: relative;
}
.project-image:hover .translate-project-animation {
  transform: translateY(0vh);
}
.project-image:focus .translate-project-animation {
  transform: translateY(0vh);
}
.translate-project-animation {
  position: absolute;
  bottom: 0px;
  transform: translateY(20vh);
  transition: transform 0.3s ease;
}
.project-description-inside {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 20vh;
  background-color: black;
  color: var(--theme-red);
}
.project-name-inside {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 10vh;
  font-weight: 700;
  font-size: 1.4em;
  background-color: black;
  color: whitesmoke;
}

.project-1-bg-image {
  overflow: hidden;
  position: relative;
}
.project-1-bg-image::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* optional: just to make sure */
}
.project-2-bg-image {
  overflow: hidden;
  background-color: black;
  position: relative;
}
.project-3-bg-image {
  overflow: hidden;
  background-color: white;
  position: relative;
}
.project-4-bg-image {
  overflow: hidden;
  position: relative;
}
.project-5-bg-image {
  overflow: hidden;
  position: relative;
}
.project-name {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.829);
  padding: 20px 0px;
  color: white;
  font-weight: 600;
  font-size: 1.1em;
}
.project-description {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.829);
  padding: 10px 0px;
  color: white;
  font-size: 1em;
}
.translate-project-2-animation {
  position: absolute;
  bottom: 0px;
  z-index: 999;
  transform: translateY(60%);
  transition: transform 0.3s ease;
}
.project-2-bg-image:hover .translate-project-2-animation {
  transform: translateY(0%);
}
.translate-project-1-animation {
  position: absolute;
  bottom: 0px;
  transform: translateY(50%);
  transition: transform 0.3s ease;
}
.project-1-bg-image:hover .translate-project-1-animation {
  transform: translateY(0%);
}

.translate-project-3-animation {
  position: absolute;
  bottom: 0px;
  transform: translateY(63%);
  transition: transform 0.3s ease;
}
.project-3-bg-image:hover .translate-project-3-animation {
  transform: translateY(0%);
}

.translate-project-4-animation {
  position: absolute;
  bottom: 0px;
  transform: translateY(63%);
  transition: transform 0.3s ease;
}
.project-4-bg-image:hover .translate-project-4-animation {
  transform: translateY(0%);
}

.translate-project-5-animation {
  position: absolute;
  bottom: 0px;
  transform: translateY(50%);
  transition: transform 0.3s ease;
}
.project-5-bg-image:hover .translate-project-5-animation {
  transform: translateY(0%);
}
.margin-top-50px {
  margin-top: 70px;
}
.animation {
  transform: translateY(50%);
  transition: transform 0.3s ease;
}
.image:hover .animation {
  transform: translateY(0%);
}

.slide-down {
  transform: scaleY(0);
  transform-origin: bottom;
}
.fs-larger {
  font-size: 3em;
}
.fs-medium-css {
  font-size: 2em;
}
.z-index-3 {
  z-index: 3;
}
.bg-navbar {
  background-color: white;
}
.text-align-justify {
  text-align: justify;
}
.testimonial-card {
  width: 100%;
}

.our-professionals-bg-image {
  background-image: url("https://growthstudio.co.in/images/our-professionals-bg.webp");
  background-size: cover;
  background-position-x: right;
  background-position-y: top;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.black-wash-timeline-background
{
  min-height: 150vh;
  background-color: rgba(0, 0, 0, 0.541);
  position: absolute;
  top: 0px;
  z-index: 2;
}
.rotate-on-hover {
  transform: rotate(0);
  transition: transform 0.5s ease;
}
.prof-card:hover .rotate-on-hover {
  transform: rotate(70deg);
  /* transform: scaleX(1.5); */
}
.margin-bottom-20px {
  margin-top: 20px;
}
.prof-card:hover .margin-bottom-20px {
  margin-top: 0px;
}
.prof-card-border {
  border: 2px solid var(--theme-dark-grey);
}
.prof-card:hover .prof-card-border {
  border: 2px solid var(--theme-red);
}

.btn-underline {
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  color: var(--theme-red);
}
.btn-underline:hover {
  text-decoration: underline;
}
.thin-grey-border {
  border: 0.5px solid var(--theme-dark-grey);
}

.bg-light-red {
  background-color: rgb(253, 210, 210);
}
.thick-grey-border {
  border: 15px solid var(--theme-dark-grey);
}
.overflow-hidden {
  overflow: hidden;
}
/* .translate-y-25 {
  animation: slideUp 15s ease-in-out infinite;
} */
.article-card-image 
{
  height: 35vh;
  background-size: cover;
}
.article-image 
{
  height: 55vh;
  background-size: cover;
}
.integrating-bg-image
{
  background-image: url("https://growthstudio.co.in/images/integrating.webp");
}
.importance-bg-image
{
  background-image: url("https://growthstudio.co.in/images/importance.webp");
}
.evolution-bg-image
{
  background-image: url("https://growthstudio.co.in/images/evolution.webp");
  background-position: center;
}
.psychology-bg-image
{
  background-image: url("https://growthstudio.co.in/images/psychology.webp");
  background-position: center;
}
@media (min-width:1100px) and (max-width:2000px)
{
  .height-70vh-testimonials
{
  height: 95vh;
}
.case-study
{
  width: 47%;
}
}
@media (min-width:900px) and (max-width:1099px)
{
  .height-70vh-testimonials
{
  height: 100vh;
}
.case-study
{
  width: 45%;
}
}
@media (min-width:700px) and (max-width:899px)
{
  .height-70vh-testimonials
{
  height:80vh;
  font-size: 0.9em;
}
.case-study
{
  width: 80%;
}
}
@media (min-width:400px) and (max-width:699px)
{
  .height-70vh-testimonials
{
  height:90vh;
  font-size: 0.8em;
}
.case-study
{
  width: 90%;
}
}
@media (min-width:200px) and (max-width:399px)
{
  .height-70vh-testimonials
{
  height:120vh;
  font-size: 0.8em;
}

.case-study
{
  width: 90%;
}
}
.nav {
  z-index: 99;
}
/* .testimony-height
{
  height: 100%;
} */
.testimonial-container
{
  position: relative;
}
.testimony-card
{
  position: absolute;
  top: 0px;
  height: 100%;
  transform: translateY(100%);
  transition: all 3s;
  opacity: 0;
}
.testimony-card.active{
  transform: translateY(0%);
  transition: all 3s;
  opacity: 1;
}
.testimony-card:not(.active){
  transform: translateY(100%);
  transition: all 3s;
  opacity: 0;
}
/* .translate-y-25:hover,
.translate-y-25:focus {
  animation-play-state: paused;
}
@keyframes slideUp {
  0% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(-100%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
} */

.width-auto {
  width: auto;
}
.footer-link {
  color: white;
  text-decoration: none;
}
.footer-link:hover {
  color: var(--theme-red);
  text-decoration: underline;
}
.height-40px {
  height: 30px;
}
.display-flex-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.footer-input-field {
  background-color: black;
  outline: none;
  border: 1px solid var(--theme-red);
  color: var(--theme-red);
  padding: 10px 20px;
}
.footer-input-field::placeholder {
  color: var(--theme-grey);
}
.footer-input-field:focus {
  background-color: black;
  outline: none;
  border: 1px solid var(--theme-red);
  color: white;
  outline: none;
}
.hover-red-border:hover {
  border: 1px solid var(--theme-red);
}

.footer-link-converse:hover {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.footer-link-converse {
  color: var(--theme-red);
  text-decoration: underline;
  text-underline-offset: 3px;
}
.about-us-bg-image {
  background-image: url("https://growthstudio.co.in/images/main-landscape-1.webp");
  background-size: cover;
  background-position-y: center;
}
.color-light-grey {
  color: rgb(175, 174, 174);
}
.scale-on-click:focus {
  transform: scale(1.5);
}
.thin-white-border {
  border: 1px solid white;
}

/* responsive navbar */
@media (min-width: 900px) {
  .display-on-large-screen {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .display-on-small-screen {
    display: none;
  }
  .services-nav-btn {
    background-color: transparent;
    border: 2px solid transparent;
    z-index: 5;
    text-align: center;
    font-weight: 800;
    width: auto;
    padding: 10px 0px;
    color: var(--theme-dark-grey);
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
.width-15 {
  width: 15%;
}

@media (max-width: 899px) {
  .display-on-small-screen {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .display-on-large-screen {
    display: none;
  }
  .services-nav-btn {
    background-color: transparent;
    border: 2px solid transparent;
    text-align: center;
    font-weight: 800;
    width: auto;
    padding: 10px 0px;
    color: var(--theme-dark-grey);
  }
}

.mainpage-bg-image-1 {
  background-image: url("https://growthstudio.co.in/growthstudio-new/images/slide-img1.jpg");
  background-size: cover;
}
.mainpage-bg-image-2 {
  background-image: url("https://growthstudio.co.in/images/div2image.webp");
  background-size: cover;
  background-position: right;
}
.white-border {
  border: 10px solid var(--theme-grey);
}
.btn-navbar-small-screen {
  background-color: transparent;
  border: 1px solid transparent;
  margin-left: 10px;
  margin-right: 10px;
  width: auto;
  outline: none;
  color: black;
  font-size: 2em;
}
.btn-navbar-small-screen:hover,
.btn-navbar-small-screen:focus {
  color: var(--theme-red);
  outline: none;
  border: 1px solid var(--theme-red);
}

.small-screen-navbar-scale-1 {
  transform: scaleX(1);
  transform-origin: left;
}
.small-screen-navbar-scale-0 {
  transform: scaleX(0);
  transform-origin: left;
}
.border-light-grey {
  border: 0.5px solid var(--theme-grey);
}
.timeline-card {
  border-bottom: 10px solid rgba(0, 0, 0, 0.501);
  border-left: 1px solid white;
}
.timeline-card-down {
  border-top: 10px solid rgba(0, 0, 0, 0.501);
  border-left: 1px solid white;
}

@media (min-width: 1100px) and (max-width: 1700px) {
  .timeline-card,
  .timeline-card-down {
    width: 12.5%;
    min-height: 40vh;
  }
  .timeline-vertex-down {
    display: block;
  }
}


@media (min-width: 800px) and (max-width: 1099px) {
  .timeline-card,
  .timeline-card-down {
    width: 25%;
    min-height: 34vh;
  }
  .timeline-vertex-down {
    display: none;
  }
}
@media (min-width: 250px) and (max-width: 800px) {
  .small-screen-cards {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
@media (min-width: 801px) and (max-width: 1600px) {
  .small-screen-cards {
    display: none;
  }
}
@media (min-width: 500px) and (max-width: 600px) {
  .timeline-vertex {
    transform: translate(-23px, 28px);
  }
}
@media (min-width: 0px) and (max-width: 499px) {
  .timeline-vertex {
    transform: translate(-25px, 0px);
  }
}
@media (min-width: 601px) and (max-width: 1600px) {
  .timeline-vertex {
    transform: translate(-23px, 30px);
  }
}
@media (min-width: 500px) and (max-width: 799px) {
  .timeline-card {
    width: 50%;
    min-height: 25vh;
    margin: 20px 0px;
  }
  .timeline-vertex,
  .timeline-vertex-down {
    display: block;
  }

  .timeline-card-down {
    display: none;
  }
}
@media (min-width: 250px) and (max-width: 499px) {
  .timeline-card,
  .timeline-card-down {
    width: 90%;
    min-height: 20vh;
    margin: 20px 0px;
  }
  .timeline-vertex,
  .timeline-vertex-down {
    display: block;
  }
  .timeline-card-down {
    display: none;
  }
}
.border-none {
  border: none;
}
.bg-black-transparent {
  background-color: rgba(0, 0, 0, 0.458);
}
.timeline-time {
  transform: translate(-20px, -20px);
}
.timeline-time-down {
  transform: translate(-20px, 20px);
}
.margin-top-negative {
  margin-top: -10px;
}
.timeline-vertex {
  width: 25px;
  height: 25px;
  background-color: var(--theme-red);
}
.timeline-vertex-down {
  width: 25px;
  min-height: 25px;
  background-color: var(--theme-red);
  transform: translate(-25px, -30px);
}
.border-white {
  border: 0.5px solid white;
}
.btn-work {
  outline: none;
  background-color: transparent;
  padding: 10px 10px;
  border: 1px solid transparent;
  color: var(--theme-dark-grey);
  font-weight: 700;
  font-size: 1.1em;
  width: auto;
  margin-top: 15px;
}
.btn-work:hover,
.btn-work:focus {
  outline: none;
  color: black;
}
.border-grey {
  border: 0.5px solid var(--theme-grey);
}
.border-grey-dark {
  border: 0.5px solid var(--theme-dark-grey);
}
.input-field {
  padding: 10px 20px;
  background-color: var(--theme-grey);
  outline: none;
  border: 1px solid transparent;
}
.input-field::placeholder {
  color: var(--theme-dark-grey);
}
.input-field:focus {
  outline: 1px solid var(--theme-red);
}
.popup-input-field {
  padding: 10px 20px;
  background-color: white;
  outline: none;
  border: 1px solid transparent;
}
.popup-input-field::placeholder {
  color: var(--theme-dark-grey);
}
.popup-input-field:focus {
  outline: 1px solid var(--theme-red);
}
.btn-social-media-icons {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--theme-grey);
  transition: all 0.5s;
  text-underline-offset: 3px;
}
.btn-social-media-icons:hover {
  color: var(--theme-red);
}
.btn-social-media-icons-mod {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--theme-dark-grey);
  transition: all 0.5s;
  text-underline-offset: 3px;
}
.btn-social-media-icons-mod:hover {
  color: var(--theme-red);
}
.border-left-grey {
  border-left: 1px solid var(--theme-grey);
}
.card-shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  transition: box-shadow 0.5s ease;
}
.card-shadow:hover,
.card-shadow:focus {
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.graph{
  height: 60vh;
}

.services-nav-btn:hover,
.services-nav-btn:focus {
  color: black;
  border-bottom: 2px solid var(--theme-dark-grey);
}
.position-relative-top {
  position: relative;
  top: 0px;
}
.position-relative-bottom {
  position: relative;
  bottom: 0px;
}

.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slide-container {
  overflow: hidden;
  height: 300px; /* Adjust height as needed */
  margin-bottom: 20px;
}

img {
  width: 100%;
  height: auto;
  transition: transform 0.5s ease;
}

button {
  cursor: pointer;
}
.image-width {
  border: 3px solid transparent;
  width: 16%;
}
.image-btn {
  padding: 0px;
  border: 3px solid transparent;
  background-color: transparent;
  transition: all 0.5s ease;
  width: 16%;
}
h1
{
  width: auto;
}
.z-index-10 {
  z-index: 10;
}
.image-btn:hover,
.image-btn:focus {
  border: 3px solid var(--theme-red);
  transform: scale(1.3);
}

.margin-bottom-negative {
  margin-bottom: -2px;
}

.cross-btn {
  height: 200px;
  width: 200px;
  background-color: var(--theme-red);
  border: 5px solid transparent;
  transform-origin: right;
  z-index: 10;
  transform: translate(200px, -50px) rotate(45deg);
}
.underline {
  text-decoration: underline;
  text-underline-offset: 4px;
}
.bg-theme-red {
  background-color: var(--theme-red);
}
.faq-btn {
  background-color: white;
  border: 0.5px solid transparent;
  height: 10vh;
}
.toggle-btn {
  border: none;
  height: 10vh;
  width: 77.35px;
}
.rotate-180deg {
  transition: all 0.6s;
  transform: rotate(180deg);
}
.transition-height {
  transition: height 0.5s ease;
}
.position-relative {
  position: relative;
  top: 0px;
}
.slide {
  position: absolute;
  top: 0px;
  left: 40px;
  opacity: 0;
  transition: all 2s ease-in-out;
  transform-origin: left;
}
.slide.active {
  opacity: 1;
  transition: all 2s ease-in-out;
  transform-origin: left;
}
.color-red {
  color: var(--theme-red);
}
.sticky-container {
  position: fixed;
  z-index: 999;
  right: 0px;
  top: 40vh;
}
.timeline-card-heading {
  font-weight: 700;
  font-size: 1.5em;
}

.btn-arrow {
  background-color: var(--theme-red);
  height: 90px;
  width: 90px;
  color: var(--theme-grey);
  border: none;
  outline: none;
  
}
.accordion-css {
  margin: 20px auto;
  border-top: none;
  transition: all 0.6s;
  z-index: 10;
}

.accordion-item-css {
  border-top: none;
  transition: all 0.6s;
  z-index: 10;
}

.accordion-item-css:first-of-type {
  border-top: none;
  transition: all 0.6s;
}
.nav-scroll{
  z-index: 99;
}
.accordion-title-css {
  background-color: transparent;
  padding-left: 20px;
  margin: 20px 0px;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;
  transition: background-color 0.3s ease;
  box-shadow: 5px 5px 5px var(--theme-dark-grey);
  transition: all 0.6s;
  min-height: 90px;
}

.accordion-title-css:hover {
  border-top: none;
  box-shadow: 10px 10px 5px var(--theme-dark-grey);
  transition: all 0.6s;
}

.accordion-title-css.active {
  background-color: transparent;
  border-top: none;
  transition: all 0.6s;
}

.accordian-title-css:focus .faq-toggle-arrow {
  transition: all 0.6s;
  transform: rotate(180deg);
}
.accordian-title-css:not(:focus) .faq-toggle-arrow {
  transition: all 0.6s;
  transform: rotate(0deg);
}

.accordion-content-css {
  max-height: 0;
  overflow: hidden;
  border-top: none;
  padding: 0 15px;
  transition: max-height 0.3s ease, padding 0.3s ease;
  transition: all 0.6s;
}
.accordion-content-css.show {
  max-height: 50vh;
  padding: 15px;
  border-top: none;
  transition: all 0.6s;
}
.article-concept {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-image: linear-gradient(
      to right,
      var(--theme-dark-grey),
      var(--theme-grey),
      white
    )
    1;
    text-align: left;
}
.position-relative-css {
  position: relative;
  top: 0px;
}
.position-sticky {
  position: sticky;
  top: 100px;
  height: auto;
}
.popular-articles-card {
  padding-left: 20px;
  border-left: 5px solid var(--theme-red);
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
}
.popular-articles-card:hover {
  background-color: var(--theme-red);
  color: var(--theme-grey);
  transition: all 0.6s;
}
.popular-articles-card:not(:hover) {
  background-color: transparent;
  color: black;
  transition: all 0.6s;
}
.color-red-article-card {
  color: var(--theme-red);
  transition: all 0.6s;
}
.popular-articles-card:hover .color-red-article-card {
  color: var(--theme-grey);
}
.popular-articles-card:not(:hover) .color-red-article-card {
  color: var(--theme-red);
}
.articles-heading:hover {
  cursor: pointer;
  text-decoration: underline;
}
.get-a-quote-form-popup {
  background-color: rgba(0, 0, 0, 0.647);
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
}
.btn-cross {
  background-color: transparent;
  color: var(--theme-grey);
  border: none;
  outline: none;
  margin-top: -130px;
  margin-right: -60px;
  font-size: 1.4em;
}
.btn-cross:hover {
  transform: rotate(180deg);
  transition: all 0.6s;
}
.btn-cross:not(:hover) {
  transform: rotate(-180deg);
  transition: all 0.6s;
}
.popup-input-field-select:focus {
  outline: 1px solid var(--theme-red);
}
.buffering {
  background-color: rgba(0, 0, 0, 0.454);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 9999;
}
.buffering-container
{  
  background-color: var(--theme-black);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 9999;
}
.height-50vh {
  height: 40vh;
}
.z-index-999 {
  z-index: 999;
}
.z-index--1 {
  z-index: -1;
}
.small-screen-navbar {
  position: fixed;
  left: 0px;
  top: 0px;
  transition: all 0.6s;
  min-height: 100vh;
}
.scale-navbar-0 {
  transform: scaleX(0);
  transform-origin: left;
  transition: all 0.6s;
}
.scale-navbar-1 {
  transform: scaleX(1);
  transform-origin: left;
  transition: all 0.6s;
}
.height-auto {
  height: auto;
}
.overflow-y-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}
.media-work 
{
  position: relative;
  height: 70vh;
}
.jingle
{
  position: absolute;
  top: 40%;
  /* background-color: rgba(0, 0, 0, 0.638); */
}
.media-work-black
{
  background-color: rgba(0, 0, 0, 0.718);
  position: absolute;
  top: 0px;
}
.btn-play
{
  background-color: transparent;
  color: var(--theme-red);
  padding: 15px 25px;
  border: none;
  font-size: 1.2em;
  outline: none;
  width: 100%;
  position: relative;
}
.lottie-jingle , .play
{
  position: absolute;
  top: 0px;
}
.chatbot-screen
{
  position: fixed;
  top: 0%;
  right: 0px;
  transform: translateY(85vh);
  transition: all 0.5s;
  height: 100vh;
  z-index: 999;
}
.chatbot-screen.active{
  transform: translateY(0vh);
  transition: all 0.5s;
}
.chatbot-screen:not(.active){
  transform: translateY(85vh);
  transition: all 0.5s;
}
.chatbot-toggle-btn 
{
  height: 15vh;
  margin-bottom: -10px;
}
.height-90vh
{
  height: 85vh;
}
























/* src/Chatbot.css */
.custom-chatbot {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  /* margin-top: -60px; */
}

.custom-chatbot .rsc-header {
  background-color: var(--theme-red) !important;
  color: var(--theme-grey) !important;
  font-size: 0.8em !important;
}

.custom-chatbot .rsc-container {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-chatbot .rsc-bot-message {
  background-color: var(--theme-red) !important;
  color: var(--theme-grey) !important;
}

.custom-chatbot .rsc-user-message {
  background-color: var(--theme-grey) !important;
  color: var(--theme-dark-grey) !important;
}

.custom-chatbot .rsc-input {
  border: 1px solid var(--theme-grey);
  border-radius: 5px;
}

.custom-chatbot .rsc-submit-button {
  background-color: var(--theme-red) !important;
  color: var(--theme-grey) !important;
}
